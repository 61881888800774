import { useEffect } from 'react'
import { UnpackNestedValue } from 'react-hook-form'

import { pickSafe, ReadonlyRecord } from '@common/lib-types'
import {
  getDatadogLogs,
  getDatadogRum,
  segmentAnalytics,
} from '@common/telemetry'
import {
  CitizenshipKeys,
  EmploymentStatusKeys,
  ERelationship,
  ResidenceStatusKeys,
  VehicleFindByKeys,
} from '@common/types'
import { isBrowser } from '@common/universal-toolkit'
import { makeSafeVoidCall, safeWindow } from '@src/utils'
import { getLeadSource, getRequestId } from '@src/utils/storage'

import { TopOfferReasonType, UIAddonProduct } from '..'

let timeout: number | null = null
const millisecondsInADay = 8.64e7

export const trackPage = (): void => {
  if (isBrowser) {
    segmentAnalytics.page().catch((error) => {
      console.error('Error tracking page', error)
    })
  }
}

export const sendTrackEvent = makeSafeVoidCall(
  async (
    eventName: string,
    properties?: ReadonlyRecord<string, unknown>,
    options?: ReadonlyRecord<string, unknown>,
  ): Promise<void> => {
    if (isBrowser) {
      await segmentAnalytics.track(eventName, properties, options)
      getDatadogRum().addAction(eventName, properties)
    } else {
      throw new Error('Can not send tracking event outside of browser')
    }
  },
)

type AnyRecord = ReadonlyRecord<string, unknown>
const buildSegmentSendTrackEvent =
  <
    TProps extends AnyRecord = AnyRecord,
    TOptions extends AnyRecord = AnyRecord,
  >(
    eventName: string,
  ) =>
  (properties?: TProps, options?: TOptions): Promise<void> => {
    return sendTrackEvent(eventName, properties, options)
  }

export type SegmentSendTrackEvent = ReturnType<
  typeof buildSegmentSendTrackEvent
>

/**
 * Identify tracking functionality
 */

type ResidenceTelemetryInfo = {
  city: string
  state: string
  zipCode: string
  status: ResidenceStatusKeys
  street: string
  residedFor: number
}

type VehicleTelemtryInfo = {
  loanBalance: number
  mileage: number
  attributes?: {
    year: number
    make: string
    model: string
    trim: string
  }
  state?: string
  estimatedMonthlyPayment?: number
  licensePlate?: string
  vin?: string
}

type CustomerTelemetryInfo = {
  email: string
  firstName: string
  lastName: string
  phone: string
  citizenship: CitizenshipKeys
  employmentStatus?: EmploymentStatusKeys
  vehicle?: VehicleTelemtryInfo
  residence?: ResidenceTelemetryInfo
  resumeUrl?: string
  uuid: string
  coborrower?: {
    uuid: string
    email: string
    firstName: string
    lastName: string
    phone: string
    citizenship: CitizenshipKeys
    ssn?: string
    birthdate: string
    relationship: ERelationship
  }
}

export const datadogIdentify = <T extends Record<string, unknown>>(
  userId: string,
  info: CustomerTelemetryInfo & T,
): void => {
  const leadSource = getLeadSource() || 'mr-consumer-site'
  const leadSourceUuid = getRequestId()

  getDatadogRum().setUser({
    // Custom attributes
    ...info,
    customer_uuid: info.uuid,
    coborrower_uuid: info?.coborrower?.uuid,
    partner_lead_source: leadSource,
    partner_lead_source_request_uuid: leadSourceUuid,

    // DD RUM built-in attributes
    id: userId,
    email: info.email,
    name: `${info.firstName} ${info.lastName}`,
  })

  getDatadogLogs().setUser({
    customer_uuid: info.uuid,
    coborrower_uuid: info?.coborrower?.uuid,
    partner_lead_source: leadSource,
    partner_lead_source_request_uuid: leadSourceUuid,
  })
}

export const identify = <T extends Record<string, unknown>>(
  userId: string,
  info: CustomerTelemetryInfo & T,
): void => {
  const leadSource = getLeadSource() || 'mr-consumer-site'
  segmentAnalytics
    .identify(userId, {
      ...info,
      leadSource,
      marketingOptOut: false,
      createdAt: new Date(Date.now()).toISOString(),
    })
    .catch((error) => {
      console.error('Error identifying user', error)
    })

  datadogIdentify(userId, info)
}
/**
 * End tracking functionality
 */

/**
 * Abandon tracking functionality
 */

const trackAbandoned = (): void => {
  segmentAnalytics.track('Abandoned', {}).catch((error) => {
    console.error('Error tracking abandoned', error)
  })
}

const resetAbandonTimer = (): void => {
  if (timeout) {
    safeWindow.clearTimeout(timeout)
  }
  timeout = safeWindow.setTimeout(() => {
    trackAbandoned()
  }, millisecondsInADay)
}

/**
 * Only use this function on top level page components.
 * Using it any lower in the component tree will make those
 * components harder to reuse, as they will initate a track abandon
 * functionality on any page that uses them.
 */
export const useTrackAbandon = (): (() => void) => {
  useEffect(() => {
    safeWindow.addEventListener('beforeunload', trackAbandoned)
    return () => safeWindow.removeEventListener('beforeunload', trackAbandoned)
  }, [])

  useEffect(() => {
    document.addEventListener('mouseover', resetAbandonTimer)
    document.addEventListener('keydown', resetAbandonTimer)
    document.addEventListener('touchstart', resetAbandonTimer)
    return () => {
      safeWindow.removeEventListener('mouseover', resetAbandonTimer)
      safeWindow.removeEventListener('keydown', resetAbandonTimer)
      safeWindow.removeEventListener('touchstart', resetAbandonTimer)

      safeWindow.clearTimeout(timeout)
    }
  }, [])

  return () => {
    safeWindow.removeEventListener('beforeunload', trackAbandoned)

    safeWindow.removeEventListener('mouseover', resetAbandonTimer)
    safeWindow.removeEventListener('keydown', resetAbandonTimer)
    safeWindow.removeEventListener('touchstart', resetAbandonTimer)

    safeWindow.clearTimeout(timeout)
    timeout = null
  }
}

/**
 * End: Abandon tracking functionality
 */

/**
 * Personal Info
 */

export const trackPersonalInfoSubmitted = buildSegmentSendTrackEvent(
  'Contact Info Submitted',
)

export const trackSSNFieldCompleted = buildSegmentSendTrackEvent(
  'SSN Field Completed',
)

export const trackCoBorrowerSectionCollapsed = buildSegmentSendTrackEvent(
  'Co-Borrower Section Collapsed',
)

export const trackCoBorrowerSectionExpanded = buildSegmentSendTrackEvent(
  'Co-Borrower Section Expanded',
)

export const trackReturnUserLoginEmailRequested = buildSegmentSendTrackEvent(
  'Return User Login Email Requested',
)

type EmailWasUsedProps = { email: string }
export const trackBorrowerEmailWasUsed = buildSegmentSendTrackEvent<
  EmailWasUsedProps,
  never
>('Borrower email was used')
export const trackCoBorrowerEmailWasUsed = buildSegmentSendTrackEvent<
  EmailWasUsedProps,
  never
>('Co-Borrower email was used')

export const trackCoBorrowerFoundEmailWasUsed = buildSegmentSendTrackEvent<
  EmailWasUsedProps,
  never
>('Co-Borrower found email was used')

type CohortOptions = {
  cohort: 'control' | 'variant'
  component: string
}
export const trackCohortDecided =
  buildSegmentSendTrackEvent<CohortOptions>('Cohort Decided')

/**
 * End: Personal Info
 */

/**
 * Residential Info
 */

export const trackResidentialInfoFormExpanded = buildSegmentSendTrackEvent(
  'Residential Form Expanded',
)

export const trackResidentialInfoFormCollapsed = buildSegmentSendTrackEvent(
  'Residential Form Collapsed',
)

export const trackResidentialInfoFormSubmitted = buildSegmentSendTrackEvent(
  'Residential Info Submitted',
)

export const trackResidentialInfoFormDropdownSelected =
  buildSegmentSendTrackEvent('Residential Info Form Dropdown Selected')

/**
 * End: Residential Info
 */

/**
 * Education Level
 */

export const trackEducationLevelFormSubmitted = buildSegmentSendTrackEvent(
  'Education Level Form Submitted',
)

export const trackEducationLevelDropdownSelected = buildSegmentSendTrackEvent(
  'Education Level Form Dropdown Selected',
)

/**
 * End: Education Level
 * Vehicle Info
 */

export const trackEstimatedMonthlyPayment = buildSegmentSendTrackEvent(
  'Vehicle Estimated Monthly Payment Filled',
)

export const trackVehicleInfoFormSubmitted = (config: {
  formName: VehicleFindByKeys
}): Promise<void> => sendTrackEvent('Vehicle Info Submitted', config)

export const trackVehicleInfoFormSelected = buildSegmentSendTrackEvent(
  'Vehicle Info Form Selected',
)

export const trackVinCallFailed = buildSegmentSendTrackEvent('VIN call failed')

/**
 * End: Vehicle Info
 */

/**
 * Residence And Emloyment
 */

export const trackResidenceAndEmploymentFormSubmitted =
  buildSegmentSendTrackEvent('Residence And Employment Form Submitted')

/**
 * End: Residence And Emloyment
 */

/**
 * Review Information
 */

export const trackReviewPageSubmitted = buildSegmentSendTrackEvent(
  'Review Page Submitted',
)

export const trackReviewPageEdited =
  buildSegmentSendTrackEvent('Review Page Edited')

/**
 * End: Review Information
 */

/**
 * Income Verification
 */

export const trackIncomeVerificationSubmitted = buildSegmentSendTrackEvent(
  'Proof of Income Confirmed',
)

/**
 * End: Income Verification
 */

/** Select Tradelines */

export const trackSelectedTradelinesPageSubmited = buildSegmentSendTrackEvent(
  'Select Tradeline Page Submitted',
)
/** End: Select Tradelines */

/** Begin: Offer Selections */
type OfferSelectionsFormData = {
  selectedOffer?: string | number
}

type CommonOptionsKeys = {
  id: string | number
  monthlyPayment: number
  rate: number
  term: number
}

type TrackOffersPresentedPageSubmitedConfig = {
  lowestOption?: CommonOptionsKeys & {
    amountFinanced: number
    isTopOffer: boolean
    topOfferReason: TopOfferReasonType
  } & unknown

  closestTerm?: CommonOptionsKeys & {
    amountFinanced: number
    isTopOffer: boolean
    topOfferReason: TopOfferReasonType
  } & unknown

  flexibleOptions: {
    options?: ReadonlyArray<
      CommonOptionsKeys & {
        isTopOffer: boolean
        topOfferReason: TopOfferReasonType
      } & unknown
    >
    amountFinanced: number
  } & unknown

  addonProducts?: ReadonlyArray<UIAddonProduct>

  data: UnpackNestedValue<OfferSelectionsFormData>
}

export const trackOffersPresentedPageSubmited = (
  options: TrackOffersPresentedPageSubmitedConfig,
): Promise<void> => {
  const { lowestOption, closestTerm, data, flexibleOptions, addonProducts } =
    options
  const standAloneOption = lowestOption || closestTerm
  const standAloneType = lowestOption ? 'Lowest Payment' : 'Similar Term'
  const flexOption = flexibleOptions.options.find(
    (opt) => opt.id === data.selectedOffer,
  )

  const offer =
    standAloneOption.id === data.selectedOffer
      ? {
          ...pickSafe(standAloneOption, [
            'amountFinanced',
            'monthlyPayment',
            'rate',
            'term',
            'isTopOffer',
            'topOfferReason',
          ]),
          addonProducts: addonProducts,
          offerUUID: standAloneOption.id,
          leadSource: getLeadSource(),
          typeOfLoan: standAloneType,
        }
      : {
          ...pickSafe(flexOption, [
            'monthlyPayment',
            'rate',
            'term',
            'isTopOffer',
            'topOfferReason',
          ]),
          addonProducts: addonProducts,
          offerUUID: flexOption.id,
          amountFinanced: flexibleOptions.amountFinanced,
          leadSource: getLeadSource(),
          typeOfLoan: 'Flexible Term',
        }
  return sendTrackEvent('Offer Selected Submitted', offer)
}

export const trackOfferSelected = (payload: {
  term: number
  offerType: string
  offerUUID: string
  nominalInterestRate: number
  combinedAgreementApr: number
  monthlyPayment: number
  amountFinanced: number
}): Promise<void> => {
  return sendTrackEvent('Offer Selected', payload)
}

export const trackOfferRendered = (payload: {
  loanApplicationUuid: string
  topOfferUuid?: string
  flexibleTermOfferUuids?: Array<string>
}): Promise<void> => {
  return sendTrackEvent('Offers Rendered', payload)
}

export const trackFlexibleTermClicked = (config: {
  term: number
  offerUUID: string | number
}): Promise<void> => {
  return sendTrackEvent('Flexible Term Clicked', config)
}

export const trackLowestPaymentDisplayed = (config: {
  term: number
  offerUUID: string | number
  rate: number
  monthlyPayment: number
  amountFinanced: number
}): Promise<void> => {
  return sendTrackEvent('Lowest Payment Displayed', config)
}

export const trackFlexibleTermDisplayed = (config: {
  amountFinanced: number
  offers: ReadonlyArray<{
    term: number
    offerUUID: string | number
    rate: number
    monthlyPayment: number
  }>
}): Promise<void> => {
  return sendTrackEvent('Flexible Term Displayed', config)
}

export const trackOffersSwiped = buildSegmentSendTrackEvent(
  'Offers Presented Swiped',
)

export const trackRecommendedTopOfferDisplayed = (config: {
  requestId: string
  id: string | number
  term: number
  rate: number
  monthlyPayment: number
  amountFinanced: number
  leadSource: string
}): Promise<void> => {
  return sendTrackEvent('Recommended Top Offer Variant Displayed', config)
}

type OffersWithAddonProductsProps = {
  offerUUID: string
  addonProducts: ReadonlyArray<UIAddonProduct>
}
export const trackAddonProductsDisplayed =
  buildSegmentSendTrackEvent<OffersWithAddonProductsProps>(
    'Addon Products Displayed',
  )

type AddonProductsEmptyProps = {
  offerUUID: string
}
export const trackAddonProductsEmpty =
  buildSegmentSendTrackEvent<AddonProductsEmptyProps>('Addon Products Empty')

type ProductId = { id: UIAddonProduct['id'] }
export const trackAddonSelected =
  buildSegmentSendTrackEvent<ProductId>('Addon Selected')

export const trackAddonUnselected =
  buildSegmentSendTrackEvent<ProductId>('Addon Unselected')

/** End: Offer Selections */

export const trackVehicleOptionsPageSubmited = buildSegmentSendTrackEvent(
  'Vehicle Options Submitted',
)

/** Create Account */

export const trackActiveDutyServiceMemberSelected = buildSegmentSendTrackEvent(
  'Active Duty Servicemember Selected',
)
export const trackTermsAndConditionSelected = buildSegmentSendTrackEvent(
  'Caribou Terms and Conditions Selected',
)
export const trackCreateAccountSubmitted = buildSegmentSendTrackEvent(
  'Create Account Submitted ',
)

export const trackAccordionShowDetailsClicked = buildSegmentSendTrackEvent(
  'Accordion Show Details Clicked',
)

export const trackAccordionHideDetailsClicked = buildSegmentSendTrackEvent(
  'Accordion Hide Details Clicked',
)

/** End: Create Account */

/** Vehicle Lookup */

export const trackVehicleLookupSubmitted = (config: {
  method: 'vin' | 'licensePlate'
}): Promise<void> => {
  return sendTrackEvent('Vehicle Lookup Submitted', config)
}

export const trackVehicleLookupMethodSelected = (config: {
  method: 'vin' | 'licensePlate'
}): Promise<void> => {
  return sendTrackEvent('Vehicle Lookup Method Selected', config)
}

/** End: Vehicle Lookup */

/** Coborrower Found */

export const trackCoborrowerFoundPageSubmitted = (config: {
  addressSameAsBorrower: boolean
}): Promise<void> => {
  return sendTrackEvent('Co-borrower Found Page Submitted', config)
}

/** End: Coborrower Found */

/**
 * Direct mail additional offer
 */
export const trackDirectMailAdditionalOffersClicked = (config: {
  property: string
  section_name: string
}): Promise<void> => {
  return sendTrackEvent('CTA Clicked', config)
}
/** End: Direct mail additional offer */

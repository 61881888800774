import qs from 'querystring'

import { refiPartnerAggBaseUrl } from '../config'

type CkCredentials = {
  requestId: string
  authToken: string
}

export const fetchPartnerCredentials = async (
  token: string,
  leadSource: string,
): Promise<CkCredentials> => {
  const res = await fetch(
    `${refiPartnerAggBaseUrl}/offers/v1/${leadSource}/auth?${qs.stringify({
      encrypted: token,
    })}`,
  )
  if (!res.ok) {
    const resText = JSON.parse(await res.text()) as { error: string }
    throw new Error(`${res.status}: ${resText.error}`)
  }
  const data = await res.json()
  return data as CkCredentials
}

import { DateTime } from 'luxon'

import { ResidenceStatus, ResidenceStatusKeys } from '@common/types'
import { parseInteger } from '@src/utils'
import { getAuthToken } from '@src/utils/storage'

import { refiApiUrl } from '../config'

export type PrequalResidence = {
  moveInDate?: string
  residenceStatus?: ResidenceStatusKeys
  monthlyPayment?: number
}

const fromRefiResidenceStatusMap: Record<string, ResidenceStatusKeys> = {
  Own: ResidenceStatus.own,
  Rent: ResidenceStatus.rent,
  Other: ResidenceStatus.other,
  'Owns Free And Clear': ResidenceStatus.ownsFreeAndClear,
  'Lives With Family': ResidenceStatus.livesWithFamily,
  'Military Housing': ResidenceStatus.militaryHousing,
}

export const getCurrentResidence = async (
  residenceUuid: string,
): Promise<PrequalResidence> => {
  const response = await fetch(
    `${refiApiUrl}/services/v1/residence/${residenceUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  const residence = await response.json()
  if (!response.ok) {
    throw new Error(residence?.message)
  }
  return {
    ...residence,
    residenceStatus: fromRefiResidenceStatusMap[residence.residenceStatus],
  }
}

const toRefiResidenceStatusMap: Record<ResidenceStatusKeys, string> = {
  [ResidenceStatus.own]: 'Own',
  [ResidenceStatus.rent]: 'Rent',
  [ResidenceStatus.other]: 'Other',
  [ResidenceStatus.ownsFreeAndClear]: 'OwnsFreeAndClear',
  [ResidenceStatus.livesWithFamily]: 'LivesWithFamily',
  [ResidenceStatus.militaryHousing]: 'MilitaryHousing',
}

export const calculateDateFromDuration = (
  years: number,
  months: number,
): string => {
  const currentTime = DateTime.now()
  const moveInDate = currentTime.minus({ years, months }).toISODate()
  return moveInDate
}

export const updateCurrentResidence = async (
  uuid: string,
  customerUuid: string,
  addressUuid: string,
  livedInYears: number,
  livedInMonths: number,
  residenceStatus?: ResidenceStatusKeys,
  monthlyPayment?: string,
): Promise<void> => {
  const refiResidenceStatus = toRefiResidenceStatusMap[residenceStatus]
  const body = JSON.stringify({
    addressUuid,
    customerUuid,
    moveInDate: calculateDateFromDuration(livedInYears, livedInMonths),
    residenceStatus: refiResidenceStatus,
    monthlyPayment: parseInteger(monthlyPayment),
  })
  const response = await fetch(`${refiApiUrl}/services/v1/residence/${uuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
    body,
  })
  if (!response.ok) {
    const data = await response.json()
    throw new Error(data?.errors)
  }
}

import React, { FC, useMemo } from 'react'
import { Box, styled } from '@mui/material'

import {
  EmploymentStatus,
  EmploymentStatusKeys,
  NewResidentialAndEmploymentInfoForm,
} from '@common/types'
import { mapEnumToDisplay } from '@src/utils'

import { ButtonFineText, StackFields, Text } from '../../reskin'

const MonthlyPaymentOver7000Container = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: '1.5rem',
}))

export const NewResidenceAndEmploymentForm: FC<
  NewResidentialAndEmploymentInfoForm
> = (props) => {
  const {
    coborrowerHasSameAddress,
    hasCoborrower,
    module: {
      SameCoborrowerAddress,
      Address,
      Apartment,
      City,
      State,
      Zip,
      MovedInYear,
      MovedInMonth,
      RentOrOwn,
      MonthlyPayment,
      EmploymentStatus,
      YearlyIncome,
      CoAddress,
      CoApartment,
      CoCity,
      CoState,
      CoZip,
      CoMovedInYear,
      CoMovedInMonth,
      CoRentOrOwn,
      CoMonthlyPayment,
      CoEmploymentStatus,
      CoYearlyIncome,
      Submit,
    },
  } = props

  const [monthlyPaymentValue, setMonthlyPaymentValue] = React.useState('')
  const monthlyPaymentOver7000 = useMemo(
    () =>
      Number(monthlyPaymentValue.replace('$', '').replaceAll(',', '')) > 7000,
    [monthlyPaymentValue],
  )

  return (
    <Box>
      <Text
        use={[
          'p',
          {
            md: 'headings.48',
            xs: 'headings.32',
          },
        ]}
        mt={{ xs: 2, sm: 6 }}
        color="text.softBlack"
        align={'left'}
      >
        Additional details
      </Text>
      <Box mt={{ xs: 4, sm: 6 }}>
        <StackFields>
          <Address />
          <Apartment />
          <City />
          <State />
          <Zip />
        </StackFields>
        <Text
          use={['p', 'subheadings.24b']}
          mt={{ xs: 4, sm: 6 }}
          mb={{ xs: 2, sm: 3 }}
          color="text.softBlack"
        >
          How long have you lived there?
        </Text>
        <StackFields>
          <MovedInYear />
          <MovedInMonth />
          <RentOrOwn />
          <Box>
            <MonthlyPayment
              onChange={(e) => setMonthlyPaymentValue(e.target.value)}
            />
            {monthlyPaymentOver7000 && (
              <MonthlyPaymentOver7000Container>
                Verify your monthly housing expense before continuing.
              </MonthlyPaymentOver7000Container>
            )}
          </Box>
          <EmploymentStatus />
          <YearlyIncome />
        </StackFields>
      </Box>
      {hasCoborrower && (
        <>
          <Text
            use={['p', 'subheadings.24b']}
            color="text.softBlack"
            mt={{ xs: 1, sm: 6 }}
            mb={2}
          >
            Does your co-borrower have the same address as you?
          </Text>
          <StackFields>
            <SameCoborrowerAddress />
          </StackFields>
        </>
      )}
      {hasCoborrower && coborrowerHasSameAddress && (
        <>
          <Text
            use={['p', 'subheadings.24b']}
            color="text.softBlack"
            align={'left'}
            mt={{ xs: 1, sm: 4 }}
            mb={2}
          >
            Your Co-Borrower’s Information
          </Text>
          <StackFields>
            <CoEmploymentStatus />
            <CoYearlyIncome />
          </StackFields>
        </>
      )}
      {hasCoborrower && !coborrowerHasSameAddress && (
        <>
          <Text
            use={['p', 'subheadings.24b']}
            color="text.softBlack"
            align={'left'}
            mt={{ xs: 1, sm: 4 }}
            mb={2}
          >
            Your Co-Borrower’s Information
          </Text>
          <StackFields mt={{ xs: 4, sm: 6 }}>
            <CoAddress />
            <CoApartment />
            <CoCity />
            <CoState />
            <CoZip />

            <CoMovedInYear />
            <CoMovedInMonth />
            <CoRentOrOwn />
            <CoMonthlyPayment />
            <CoEmploymentStatus />
            <CoYearlyIncome />
          </StackFields>
        </>
      )}
      <Box mt={{ xs: 4, sm: 6 }}>
        <ButtonFineText button={Submit} />
      </Box>
    </Box>
  )
}

export const mapEmploymentToDisplay = (value: EmploymentStatusKeys): string =>
  value === EmploymentStatus.SelfEmployed
    ? 'Self-employed'
    : mapEnumToDisplay(value)

export enum ELender {
  Prestige = 'prestige',
  Foursight = 'foursight',
  Beneficial = 'beneficial',
  Genisys = 'genisys',
  Greenstate = 'greenstate',
  Sensible = 'sensible',
  Infirst = 'infirst',
  Blue = 'blue',
  ConciergeMr = 'concierge_mr',
  Cnf = 'cnf',
  OneMain = 'one_main',
  Peoples = 'peoples',
  Naln = 'naln',
  Motorefi = 'motorefi',
  Concierge = 'concierge',
  Flagship = 'flagship',
  Carter = 'carter',
  Consumers = 'consumers',
  ArizonaCentral = 'arizona_central',
  Hughes = 'hughes',
  Innovate = 'innovate',
  Rogue = 'rogue',
  Tower = 'tower',
  Penfed = 'penfed',
  Usalliance = 'usalliance',
  Agfed = 'agfed',
  Axos = 'axos',
  Becu = 'becu',
  Eecu = 'eecu',
  Evansville = 'evansville',
  Caribou = 'caribou',
}

/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react'
import { Container, styled } from '@mui/material'
import cx from 'classnames'

import { OmitSafe } from '@common/lib-types'
import { Box, Link } from '@common/react-lib-base'
import { SelectedOffer, VehicleLookupFormModule } from '@common/types'
import { makeStyles, Theme } from '@modules/core-ui'
import { customLenderProcessingFees } from '@src/config'

import { Heading } from '../heading'
import { SelectedLoanInfo } from '../selected-loan-info'

import { BBB, Rating } from './badges'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(0, 1),
    maxWidth: '1200px',
  },

  heading: {
    textAlign: 'center',
    maxWidth: 'none',
    marginBottom: theme.spacing(1),
  },

  subheading: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: 0,
  },

  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },

  content: {
    display: 'flex',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  narrowColumn: {
    width: '40%',
    marginRight: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginRight: 0,
      marginBottom: theme.spacing(4),
    },
  },

  iconContainer: {
    height: 64,
    marginRight: theme.spacing(2),

    '& svg': {
      height: '100%',
      width: 'auto',
    },
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    marginLeft: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },

  badgesContainer: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
  },

  vehicleSelectionTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
  },

  vehicleSelectionContainer: {
    width: '100%',
    display: 'flex',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.grey[500],
  },

  lookupMenu: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  menuHelpText: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[800],
    textAlign: 'center',
  },
}))

const useBtnStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 'normal',
    display: 'inline-flex',
    flexDirection: 'column',
    verticalAlign: 'top',
    marginBottom: theme.spacing(2),
    border: `2px solid ${theme.palette.grey[600]}`,
    borderRadius: 6,
    padding: theme.spacing(3),
    boxShadow: 'none',
    '&:hover, &:active, &:focus': {
      boxShadow: 'none',
    },
  },

  text: {
    color: theme.palette.common.black,
  },
  contained: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
}))

const MainContainer = styled(Container)({
  width: '100%',
  display: 'flex',
  maxWidth: '1200px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '6px',
})

export const VehicleLookupForm: FC<{
  module: OmitSafe<VehicleLookupFormModule, 'Submit'>
  data: SelectedOffer
}> = (props) => {
  const { Dynamic, SelectLicensePlate, SelectVin } = props.module
  const {
    amount_financed,
    selected_products = [],
    term,
    combined_agreement_apr: combinedAgreementApr,
    current_monthly_payment: monthlyPayment,
    monthly_payment: newMonthlyPayment,
    vehicle_make,
    vehicle_model,
    vehicle_trim,
    vehicle_year,
    remaining_loan_term,
    lender_name: lenderName,
  } = props.data || {}

  const classes = useStyles()
  const btnClasses = useBtnStyles()

  const monthlySavings = monthlyPayment - Number.parseFloat(newMonthlyPayment)
  // Currently custom lender processing fees are not saved in refi when returning selected offers, so we need to add it here.
  const processingFee: number = customLenderProcessingFees[lenderName] || 499
  const amountFinanced = (
    Number.parseFloat(amount_financed) + processingFee
  ).toString()
  const totalSavings =
    remaining_loan_term * monthlyPayment -
    term * Number.parseFloat(newMonthlyPayment)

  return (
    <MainContainer>
      <Box>
        <Heading
          className={cx(classes.heading)}
        >{`Nice job! Your account is created! Now, let's find your vehicle.`}</Heading>
        <h3
          className={cx(classes.subheading)}
        >{`We need to locate your vehicle's online record for your application.`}</h3>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.narrowColumn}>
          <SelectedLoanInfo
            amountFinanced={amountFinanced}
            make={vehicle_make}
            model={vehicle_model}
            year={vehicle_year}
            monthlyPayment={Number(newMonthlyPayment)}
            combinedAgreementApr={combinedAgreementApr}
            term={term.toString()}
            trim={vehicle_trim}
            monthlySavings={monthlySavings}
            totalSavings={totalSavings}
            selectedProducts={selected_products}
          />

          <Box className={classes.badgesContainer}>
            <Box className={classes.iconContainer}>
              <Link
                href="https://www.bbb.org/us/dc/washington/profile/auto-financing/caribou-formerly-motorefi-0241-236020294#sealclick"
                target="blank"
              >
                <BBB />
              </Link>
            </Box>
            <Box className={classes.iconContainer}>
              <Rating />
            </Box>
          </Box>
        </Box>

        <Box className={classes.column}>
          <span className={classes.vehicleSelectionTitle}>
            {"Let's find your vehicle. Choose an option below:"}
          </span>

          <Box className={classes.vehicleSelectionContainer}>
            {Dynamic ? (
              <Dynamic />
            ) : (
              <Box className={classes.lookupMenu}>
                <SelectLicensePlate classes={btnClasses} variant="contained">
                  <span className={btnClasses.text}>
                    {'Find by '}
                    <span className={classes.bold}>{' License Plate'}</span>
                  </span>
                </SelectLicensePlate>
                <SelectVin classes={btnClasses} variant="contained">
                  <span className={btnClasses.text}>
                    {'Find by '}
                    <span className={classes.bold}>{'VIN'}</span>
                  </span>
                </SelectVin>

                <span className={classes.menuHelpText}>
                  {'Find your VIN on your Vehicle Registration'}
                </span>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </MainContainer>
  )
}

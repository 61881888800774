/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useCallback, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import {
  Button as CtaButton,
  Notification,
} from '@common/react-lib-consumer-pres'
import {
  EmploymentStatus,
  EmploymentStatusKeys,
  NewResidenceAndEmploymentFormModule as ResidenceAndEmploymentFormModule,
  ResidenceStatus,
  State,
  stateToDisplay,
} from '@common/types'
import { generateMonths, generateYears, mapEnumToDisplay } from '@src/utils'

import { ResidenceEmploymentForm } from '../../schemas'
import { Element, getGoogleAddress, useModule } from '../../utils'
import {
  CurrencyInput as CurrencyField,
  PatternInput as PatternField,
  RadioGroup,
  Select as SelectField,
  TextField,
} from '../controlled-fields'

type ResidenceAndEmploymentModuleProps = {
  form: UseFormReturn<ResidenceEmploymentForm>
  submit: (formvalues?: ResidenceEmploymentForm) => Promise<void>
  submitError: string
}

const mapEmploymentToDisplay = (value: EmploymentStatusKeys): string =>
  value === EmploymentStatus.SelfEmployed
    ? 'Self-employed'
    : mapEnumToDisplay(value)

export const useResidenceAndEmploymentFormModule = ({
  form: { handleSubmit, formState, control, setValue },
  submit,
  submitError,
}: ResidenceAndEmploymentModuleProps): ResidenceAndEmploymentFormModule => {
  const [showError, setShowError] = useState(false)
  const hideWarning = useCallback(() => setShowError(false), [])

  useEffect(() => {
    setShowError(!!submitError)
  }, [submitError])

  const { isSubmitting, isSubmitSuccessful } = formState

  const mod = useModule({
    SubmitError:
      showError &&
      Element(Notification, {
        type: 'error',
        title: 'Error',
        body: submitError,
        onClose: hideWarning,
      }),
    Submit: Element(CtaButton, {
      onClick: handleSubmit(submit),
      loading: isSubmitting || isSubmitSuccessful,
      children: 'Continue',
      id: 'continue',
    }),
    SameCoborrowerAddress: Element(RadioGroup, {
      control,
      name: 'coborrowerResidence',
      items: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
      onChange: (v: boolean) => {
        setValue('coborrowerResidence', v, { shouldValidate: true })
        setValue('coyearlyIncome', '')
        setValue('coemploymentStatus', '' as EmploymentStatusKeys)
      },
    }),
    Address: Element(TextField, {
      control,
      label: 'Street Address',
      onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
        getGoogleAddress({ AddressElement: e.target, setValue })
      },
      name: 'address',
    }),
    Apartment: Element(TextField, {
      control,
      label: 'Apartment or Suite # (Optional)',
      placeholder: 'Suite #',
      name: 'apartment',
      autoComplete: 'nope',
    }),
    City: Element(TextField, {
      control,
      label: 'City',
      placeholder: 'City',
      name: 'city',
      autoComplete: 'nope',
    }),
    State: Element(SelectField, {
      control,
      label: 'State',
      name: 'state',
      menuItems: [
        {
          displayName: 'Select',
          value: '',
        },
        ...Object.values(State).map((state) => ({
          displayName: stateToDisplay(state),
          value: state,
        })),
      ],
      autoComplete: 'nope',
    }),
    Zip: Element(PatternField, {
      control,
      name: 'zip',
      format: '#####',
      mask: '_',
      label: 'Zip Code',
      placeholder: '5 digit zip code',
      autoComplete: 'nope',
    }),
    MovedInYear: Element(SelectField, {
      control,
      name: 'livedInAddress.years',
      label: 'Year',
      menuItems: [
        {
          displayName: 'Select',
          value: '',
        },
        ...generateYears().map((value) => ({
          value,
          displayName: value,
        })),
      ],
    }),
    MovedInMonth: Element(SelectField, {
      control,
      name: 'livedInAddress.months',
      label: 'Months',
      menuItems: [
        {
          displayName: 'Select',
          value: '',
        },
        ...generateMonths().map((value) => ({
          value,
          displayName: value,
        })),
      ],
    }),
    RentOrOwn: Element(SelectField, {
      control,
      label: 'Do you rent or own?',
      name: 'rentOrOwn',
      menuItems: [
        {
          displayName: 'Select an option',
          value: '',
        },
        ...Object.values(ResidenceStatus).map((value) => ({
          value,
          displayName: mapEnumToDisplay(value),
        })),
      ],
    }),
    MonthlyPayment: Element(CurrencyField, {
      control,
      label: 'Monthly rent/mortgage',
      name: 'monthlyPayment',
    }),
    EmploymentStatus: Element(SelectField, {
      control,
      label: 'Select employment status',
      name: 'employmentStatus',
      menuItems: [
        {
          displayName: 'Select status',
          value: '',
        },
        ...Object.values(EmploymentStatus).map((value) => ({
          value,
          displayName: mapEmploymentToDisplay(value),
        })),
      ],
    }),
    YearlyIncome: Element(CurrencyField, {
      control,
      label: 'Yearly income (before taxes)',
      name: 'yearlyIncome',
    }),
    CoAddress: Element(TextField, {
      control,
      label: 'Street Address',
      onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
        getGoogleAddress({
          AddressElement: e.target,
          setValue,
          coaddress: true,
        })
      },
      name: 'coaddress',
    }),
    CoApartment: Element(TextField, {
      control,
      label: 'Apartment or Suite # (Optional)',
      placeholder: 'Suite #',
      name: 'coapartment',
      autoComplete: 'nope',
    }),
    CoCity: Element(TextField, {
      control,
      label: 'City',
      placeholder: 'City',
      name: 'cocity',
      autoComplete: 'nope',
    }),
    CoState: Element(SelectField, {
      control,
      label: 'State',
      name: 'costate',
      autoComplete: 'nope',
      menuItems: [
        {
          displayName: 'Select',
          value: '',
        },
        ...Object.values(State).map((state) => ({
          displayName: stateToDisplay(state),
          value: state,
        })),
      ],
    }),
    CoZip: Element(PatternField, {
      control,
      label: 'Zip Code',
      placeholder: '5 digit zip code',
      name: 'cozip',
      autoComplete: 'nope',
      format: '#####',
      mask: '_',
    }),
    CoMovedInYear: Element(SelectField, {
      control,
      label: 'Years',
      name: 'colivedInAddress.years',
      menuItems: [
        {
          displayName: 'Select',
          value: '',
        },
        ...generateYears().map((value) => ({
          value,
          displayName: value,
        })),
      ],
    }),
    CoMovedInMonth: Element(SelectField, {
      control,
      label: 'Months',
      name: 'colivedInAddress.months',
      menuItems: [
        {
          displayName: 'Select',
          value: '',
        },
        ...generateMonths().map((value) => ({
          value,
          displayName: value,
        })),
      ],
    }),
    CoRentOrOwn: Element(SelectField, {
      control,
      label: 'Do you rent or own?',
      name: 'corentOrOwn',
      menuItems: [
        {
          displayName: 'Select an option',
          value: '',
        },
        ...Object.values(ResidenceStatus).map((value) => ({
          value,
          displayName: mapEnumToDisplay(value),
        })),
      ],
    }),
    CoMonthlyPayment: Element(CurrencyField, {
      control,
      label: 'Monthly rent/mortgage',
      name: 'comonthlyPayment',
    }),
    CoEmploymentStatus: Element(SelectField, {
      control,
      label: 'Select employment status',
      name: 'coemploymentStatus',
      menuItems: [
        {
          displayName: 'Select status',
          value: '',
        },
        ...Object.values(EmploymentStatus).map((value) => ({
          value,
          displayName: mapEmploymentToDisplay(value),
        })),
      ],
    }),
    CoYearlyIncome: Element(CurrencyField, {
      control,
      label: 'Yearly income (before taxes)',
      name: 'coyearlyIncome',
    }),
  })

  return mod
}

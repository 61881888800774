import React, { FC } from 'react'
import { Box, styled } from '@mui/material'

import { Link } from '@common/react-lib-consumer-pres'
import { NewPersonalFormModule as PersonalFormModule } from '@common/types'

import { ButtonFineText, ProgressBar, Text } from '../../reskin'

import { PersonalInformationForm } from './personal-information-form'

const Section = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  width: '100%',
}))

const CosignerContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: 'flex',
  flexDirection: 'row',

  '& > *': {
    marginRight: theme.spacing(2),
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
    '& > *:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}))

const PrivacyPolicy: FC = () => (
  <Text use={['p', 'paragraphs.14']}>
    By clicking &apos;Continue&apos;, I agree to the{' '}
    <Link
      href="https://www.caribou.com/electronic-communications-agreement"
      target="_blank"
    >
      <b>
        <u>{'Electronic Communications Consent '}</u>
      </b>
    </Link>
    and that Caribou may contact me about available offers and other Caribou
    products or services through any means, including emails, SMS, text
    messages, and calls to the email address and phone number provided. I also
    agree that Caribou may contact me by voice and text at the telephone number
    provided using pre-recorded messages, artificial voice, automatic telephone
    dialing systems, and an automatic texting system. Giving consent to receive
    voice calls and text messages through these methods is not required to
    purchase any products or services. I may revoke consent to any marketing
    communications by emailing{' '}
    <Link href="mailto:contact@gocaribou.com" target="_blank">
      <b>
        <u>contact@gocaribou.com</u>
      </b>
    </Link>
    .
  </Text>
)

type Props = {
  module: PersonalFormModule
  Banner?: FC
}
export const PersonalAndCoborrowerForm: FC<Props> = ({
  module: { SelectCosigner, Submit, borrower, coborrower },
  Banner,
}) => {
  return (
    <>
      <Box width="100%">
        <ProgressBar activeStep={0} />
      </Box>
      <Banner></Banner>
      <Text
        use={['h2', { xs: 'headings.32', md: 'headings.48' }]}
        color="text.softBlack"
        mt={{ xs: 4, md: 6 }}
        mb={2}
      >
        Share a bit about yourself
      </Text>

      <Section>
        <PersonalInformationForm module={borrower} />
      </Section>

      <Section>
        <Text use={['h3', 'subheadings.24b']} color="text.softBlack" mb={3}>
          {'Is there anyone else on your current loan?'}
        </Text>
        <CosignerContainer>
          <SelectCosigner />
        </CosignerContainer>
      </Section>

      {coborrower && (
        <>
          <Text
            use={['h2', { xs: 'headings.32', md: 'headings.48' }]}
            color="text.softBlack"
            mt={{ xs: 4, md: 6 }}
            mb={2}
          >
            {'Tell us about your co-borrower'}
          </Text>

          <Section>
            <PersonalInformationForm module={coborrower} />
          </Section>
        </>
      )}

      <Section>
        <PrivacyPolicy />
      </Section>

      <Section>
        <ButtonFineText button={Submit} />
      </Section>
    </>
  )
}

import { Logger } from '@src/hooks/use-logger'
import {
  getAuthToken,
  getLeadSourceQueryParams,
  getRequestId,
} from '@src/utils/storage'

import { consumerXpAggregatorUrl } from '../config'

export const getRedirectPath = async (): Promise<{ redirectUrl: string }> => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/${getRequestId()}/new-redirect-url${getLeadSourceQueryParams()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )

  return await response.json()
}

export const getNewResumeUrl = async (): Promise<{ resumeUrl: string }> => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/${getRequestId()}/resume${getLeadSourceQueryParams()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )

  return await response.json()
}

export const getLoanState = async (
  logger?: Logger,
): Promise<LoanApplicationState> => {
  const requestId = getRequestId()

  if (!requestId) {
    logger?.error('Request ID is missing', { requestId })
    throw new Error('Request ID is missing')
  }

  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/${requestId}/loan-application-state${getLeadSourceQueryParams()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )

  const data = await response.json()
  if (!response.ok) {
    logger?.error('Failed to get loan application state', { data })

    throw new Error(data?.message)
  }
  return data
}

export const getResumeUrlByLoanApplication = async (
  loanApplicationUuid: string,
): Promise<{ resumeUrl: string }> => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/${loanApplicationUuid}/la-resume`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )

  return await response.json()
}

export type LoanApplicationState = {
  resumeUrl: string
  aasmState: string
  loanUrl: string
  loanApplicationIssues?: LoanApplicationIssuesState
  loanRoutes?: Map<string, string>
  directMailApp?: boolean
}

type LoanApplicationIssuesState = {
  /* eslint-disable @typescript-eslint/naming-convention */
  is_joint_loan: boolean
  is_credit_frozen: boolean
  frozen_credit_bureau_name: {
    primary_borrower: ReadonlyArray<string> | null
    secondary_borrower: ReadonlyArray<string> | null
  }
  /* eslint-enable @typescript-eslint/naming-convention */
}

import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import startCase from 'lodash/startCase'
import { useRouter } from 'next/router'

import { Button, Notification } from '@common/react-lib-consumer-pres'
import {
  EmployementInfoFormModule,
  EmploymentStatus,
  EmploymentStatusKeys,
  PageRoute,
  State,
  StateKeys,
  stateToDisplay,
} from '@common/types'
import { generateMonths, generateYears } from '@src/utils'

import { submitEmployment } from '../../../api'
import {
  useGetNextUrlByLoanState,
  useLogger,
  useResolver,
} from '../../../hooks'
import {
  EmploymentInfo as EmploymentInfoSchema,
  EmploymentInfoForm,
} from '../../schemas'
import { Element, genericErrorMessage, useModule } from '../../utils'
import {
  PatternInput,
  PhoneInput,
  Select,
  TextField,
} from '../controlled-fields'

type PrequeEmploymentInfo = Exclude<
  EmploymentStatusKeys,
  typeof EmploymentStatus.SelfEmployed | typeof EmploymentStatus.Student
>

const mapEnumToDisplay = (relationship: string): string =>
  startCase(relationship?.toLowerCase?.())

export const useEmploymentFormModule = (): {
  module: EmployementInfoFormModule
  isDurationActive: boolean
} => {
  const [submitError, setSubmitError] = useState('')

  const hideWarning = useCallback(() => setSubmitError(''), [])

  const { handleSubmit, formState, control, watch, reset } =
    useForm<EmploymentInfoForm>({
      mode: 'onTouched',
      shouldFocusError: true,
      defaultValues: {
        employmentStatus: EmploymentStatus.Employed as PrequeEmploymentInfo,
        employerName: '',
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '' as StateKeys,
        zip: '',
        duration: {
          months: '',
          years: '',
        },
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resolver: useResolver(EmploymentInfoSchema),
    })
  const router = useRouter()
  const logger = useLogger()
  const getNextUrl = useGetNextUrlByLoanState()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const prefetch = async () => {
      await router.prefetch(PageRoute.VehicleLookup)
    }
    prefetch().catch(console.error)
  }, [router])

  const submitForm = handleSubmit(
    async ({ duration, ...payload }): Promise<void> => {
      try {
        setSubmitError('')
        await submitEmployment({
          ...payload,
          duration: {
            years: Number.parseInt(duration.years),
            months: Number.parseInt(duration.months),
          },
        })
        const resumeUrl = await getNextUrl()
        void router.push(resumeUrl)
      } catch (error) {
        setSubmitError(genericErrorMessage)
        logger.error('Error submitting employment info: ', { error })
      }
    },
  )

  const employmentStatus =
    watch('employmentStatus') || EmploymentStatus.Employed

  const isDurationActive =
    !!watch('duration.years') || !!watch('duration.months')

  useEffect(() => {
    reset({
      employmentStatus,
      employerName: '',
      streetAddress1: '',
      streetAddress2: '',
      city: '',
      state: '' as StateKeys,
      zip: '',
      duration: {
        months: '',
        years: '',
      },
    })
  }, [employmentStatus, reset])

  const { isSubmitting, isSubmitSuccessful } = formState

  const mod = useModule({
    SubmitError:
      submitError &&
      Element(Notification, {
        type: 'error',
        title: 'Error',
        body: submitError,
        onClose: hideWarning,
      }),
    Submit: Element(Button, {
      onClick: submitForm,
      loading: isSubmitting || isSubmitSuccessful,
      children: 'Continue',
    }),
    EmploymentStatus: Element(Select, {
      label: 'What is your employment status?',
      control,
      name: 'employmentStatus',
      menuItems: [
        {
          displayName: mapEnumToDisplay(EmploymentStatus.Employed),
          value: EmploymentStatus.Employed,
        },
        {
          displayName: mapEnumToDisplay(EmploymentStatus.Unemployed),
          value: EmploymentStatus.Unemployed,
        },
        {
          displayName: mapEnumToDisplay(EmploymentStatus.Retired),
          value: EmploymentStatus.Retired,
        },
      ],
    }),
    Name: Element(TextField, {
      label: 'Name of your employer',
      control,
      name: 'employerName',
      disabled: employmentStatus !== EmploymentStatus.Employed,
    }),
    Address: Element(TextField, {
      label: 'Street address (Optional)',
      control,
      name: 'streetAddress1',
      disabled: employmentStatus !== EmploymentStatus.Employed,
    }),
    Apartment: Element(TextField, {
      label: 'Apartment or suite # (Optional)',
      control,
      name: 'streetAddress2',
      placeholder: 'Suite #',
      disabled: employmentStatus !== EmploymentStatus.Employed,
    }),
    City: Element(TextField, {
      label: 'City (Optional)',
      control,
      name: 'city',
      placeholder: 'City',
      disabled: employmentStatus !== EmploymentStatus.Employed,
    }),
    State: Element(Select, {
      label: 'State',
      control,
      name: 'state',
      disabled: employmentStatus !== EmploymentStatus.Employed,
      menuItems: [
        {
          displayName: 'Select a state',
          value: '',
        },
        ...Object.values(State).map((state) => ({
          displayName: stateToDisplay(state),
          value: state,
        })),
      ],
    }),
    PhoneNumber: Element(PhoneInput, {
      control,
      name: 'phoneNumber',
      label: 'Employer phone number (Optional)',
      disabled: employmentStatus !== EmploymentStatus.Employed,
    }),
    Zip: Element(PatternInput, {
      control,
      name: 'zip',
      format: '#####',
      placeholder: '5 digit zip code',
      mask: '_',
      disabled: employmentStatus !== EmploymentStatus.Employed,
      label: 'Zip code (Optional)',
    }),
    WorkedInYear: Element(Select, {
      control,
      name: 'duration.years',
      label: '',
      disabled: employmentStatus !== EmploymentStatus.Employed,
      menuItems: [
        {
          displayName: 'Years',
          value: '',
        },
        ...generateYears(61).map((value) => ({
          value,
          displayName: value,
        })),
      ],
    }),
    WorkedInMonth: Element(Select, {
      control,
      name: 'duration.months',
      label: '',
      disabled: employmentStatus !== EmploymentStatus.Employed,
      menuItems: [
        {
          displayName: 'Months',
          value: '',
        },
        ...generateMonths().map((value) => ({
          value,
          displayName: value,
        })),
      ],
    }),
  })
  return { module: mod, isDurationActive }
}

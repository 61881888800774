import { useMemo } from 'react'
import { Resolver, useForm } from 'react-hook-form'
import mapValues from 'lodash/mapValues'

import { mapEmploymentToDisplay } from '@common/react-lib-consumer-pres'
import {
  EmploymentStatus,
  NewReviewResidenceFormModule,
  ResidenceStatus,
  ReviewInfoData,
  State as EState,
} from '@common/types'
import { residenceAndEmploymentAdapters } from '@src/api'
import { useResolver } from '@src/hooks'
import { trackReviewPageEdited } from '@src/tracking'
import { mapEnumToDisplay, parseInteger } from '@src/utils'

import { ReviewResidenceEmployment } from '../../schemas'
import { Element, getGoogleAddress, useModule } from '../../utils'
import {
  CurrencyInput,
  NumberInput,
  PatternInput,
  Select,
  TextField,
} from '../controlled-fields'

import { useFormControls } from './use-form-controls'
import { useTrackChanges } from './use-track-changes'

type ResidenceInfo = ReviewInfoData['residence']

export const useNewResidenceEmploymentForm = (
  data: ResidenceInfo,
  setData: (d: Partial<ResidenceInfo>) => void,
  getOpenForm: () => HTMLDivElement | void,
): NewReviewResidenceFormModule => {
  const clearedData = useMemo(
    () =>
      typeof data.yearlyIncome == 'string' && data.yearlyIncome
        ? {
            ...data,
            yearlyIncome: Number.parseInt(data.yearlyIncome),
          }
        : data,
    [data],
  )

  const form = useForm<ResidenceInfo>({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: clearedData,
    // TODO: find a better typescript solution for dealing with conversion of
    // numbers to strings for form values
    resolver: useResolver(
      ReviewResidenceEmployment,
    ) as unknown as Resolver<ResidenceInfo>,
  })

  const {
    control,
    formState: { isSubmitting },
    setValue,
  } = form

  const trackResidenceEmploymentChange = useTrackChanges(
    data,
    trackReviewPageEdited,
  )

  const submit = async ({
    monthlyPayment,
    yearlyIncome,
    livedInAddress,
    ...data
  }: ResidenceInfo): Promise<void> => {
    const clearedData = {
      ...data,
      monthlyPayment: parseInteger(monthlyPayment),
      yearlyIncome: parseInteger(yearlyIncome),
      livedInAddress: mapValues(livedInAddress, parseInteger),
    }

    const residence =
      await residenceAndEmploymentAdapters.updateResidenceEmployment(
        clearedData,
      )

    setData(residence)
    trackResidenceEmploymentChange(residence)
  }

  return {
    ...useFormControls(clearedData, submit, form, getOpenForm),
    module: useModule({
      Address: Element(TextField, {
        control,
        label: 'Address',
        disabled: isSubmitting,
        name: 'address',
        placeholder: 'Street address',
        onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
          getGoogleAddress({ AddressElement: e.target, setValue })
        },
      }),
      Apartment: Element(TextField, {
        control,
        label: 'Apartment',
        disabled: isSubmitting,
        name: 'apartment',
        autoComplete: 'nope',
      }),
      City: Element(TextField, {
        control,
        label: 'City',
        disabled: isSubmitting,
        name: 'city',
        placeholder: 'City',
        autoComplete: 'nope',
      }),
      State: Element(Select, {
        control,
        label: 'State',
        disabled: isSubmitting,
        name: 'state',
        autoComplete: 'nope',
        menuItems: Object.values(EState).map((value) => ({
          value,
          displayName: value,
        })),
      }),
      Zip: Element(PatternInput, {
        control,
        label: 'Zip code',
        disabled: isSubmitting,
        name: 'zip',
        format: '#####',
        autoComplete: 'nope',
        mask: '_',
      }),
      ResidenceStatus: Element(Select, {
        control,
        label: 'Residence status',
        disabled: isSubmitting,
        name: 'rentOrOwn',
        menuItems: Object.values(ResidenceStatus).map((value) => ({
          value,
          displayName: mapEnumToDisplay(value),
        })),
      }),
      MonthlyPayment: Element(CurrencyInput, {
        control,
        label: 'Monthly payment',
        disabled: isSubmitting,
        name: 'monthlyPayment',
      }),
      Years: Element(NumberInput, {
        control,
        label: 'Years',
        disabled: isSubmitting,
        name: 'livedInAddress.years',
        placeholder: 'Years',
      }),
      Months: Element(NumberInput, {
        control,
        label: 'Months',
        disabled: isSubmitting,
        name: 'livedInAddress.months',
        placeholder: 'Months',
      }),
      EmploymentStatus: Element(Select, {
        control,
        label: 'Employment status',
        disabled: isSubmitting,
        name: 'employmentStatus',
        menuItems: Object.values(EmploymentStatus).map((value) => ({
          value,
          displayName: mapEmploymentToDisplay(value),
        })),
      }),
      YearlyIncome: Element(CurrencyInput, {
        control,
        label: 'Yearly income',
        disabled: isSubmitting,
        name: 'yearlyIncome',
      }),
    }),
  }
}
